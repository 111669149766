import React from 'react';
import PropTypes from 'prop-types';

export default class AdditionalInfo extends React.PureComponent {

  render () {
    return (
      <div className='additional-info'>
        <h2>お知らせ(information)</h2>
        <ul className='kakomi'>
          <li><h3>ボカロ丼Wiki</h3></li>
          <li>
            <ul>
              <li>ボカロ丼メンバー向けのお知らせなどはこちらを御覧ください。会員ページは要ログイン。</li>
              <li><a href="http://wiki.vocalodon.net/" target="_blank"><span>http://wiki.vocalodon.net/</span></a></li>
              <li>User : rin</li>
              <li>Password : len</li>
            </ul>
          </li>
          <li><h3>ボカロ丼Info</h3></li>
          <li>
            <ul>
              <li>ボカロ丼外の人向けの告知サイトです。内容更新希望者はTOMOKI++まで。</li>
              <li><a href="http://info.vocalodon.net/" target="_blank"><span>http://info.vocalodon.net/</span></a></li>
            </ul>
          </li>
          <li><h3>公式コンピ販売</h3></li>
          <li>
            <ul>
              <li>ボカロ丼のサーバ代は公式コンピの売り上げで賄われています : <br /><a href="https://ec.toranoana.shop/tora/ec/cot/circle/2UPA0C6Q8473dB60d687/all/" target="_blank"><span>とらのあな</span></a></li>
            </ul>
          </li>
          <li><h3>ボカロ丼Discord（音声チャット）</h3></li>
          <li>
            <ul>
              <li>招待コード(invitation) : <br /><a href="https://discord.gg/Egpt4Q3"><span>https://discord.gg/Egpt4Q3</span></a></li>
            </ul>
          </li>
          <li><h3>避難所(Misskey)</h3></li>
          <li>
            <ul>
              <li><a href="https://misskey.vocalodon.net" target="_blank">https://misskey.vocalodon.net</a></li>
              <li>招待コード：NYWWSJSB</li>
            </ul>
          </li>
        </ul>
        <h2>主なハッシュタグ</h2>
        <ul className='kakomi'>
          <li><a href="/web/timelines/tag/ボカロ丼自己紹介タイム">#ボカロ丼自己紹介タイム</a></li>
          <li><a href="/web/timelines/tag/ボカロ丼業務連絡タイム">#ボカロ丼業務連絡タイム</a></li>
          <li><a href="/web/timelines/tag/ボカロ丼お絵描き部">#ボカロ丼お絵描き部</a></li>
          <li><a href="/web/timelines/tag/曲紹介">#曲紹介</a></li>
          <li><a href="/web/timelines/tag/自貼り">#自貼り</a></li>
          <li><a href="/web/timelines/tag/vocanew">#vocanew</a></li>
        </ul>
        <h2>管理者</h2>
        <ul className='kakomi'>
          <li><a href="/web/accounts/1">TOMOKI++</a></li>
        </ul>
      </div>
    );
  }
}
